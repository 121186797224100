"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _default = exports.default = {
  components: {
    pagination: _index.default
  },
  mixins: [_mixins.default.list],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      required: true
    },
    tableCols: {
      type: Array,
      required: true
    },
    pageMeta: {
      type: Object,
      required: true
    },
    goFunc: {
      type: Function,
      required: true
    },
    changeSizeFunc: {
      type: Function,
      required: true
    },
    operations: {
      type: Array,
      default: () => []
    },
    searchBarRight: {
      type: Array,
      default: () => []
    },
    searchBarLeft: {
      type: Boolean,
      default: false
    },
    showSearchButton: {
      type: Boolean,
      default: true
    },
    operationWidth: {
      type: Number,
      default: null
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    supportSelection: {
      type: Boolean,
      default: false
    },
    handleSelectionChange: {
      type: Function,
      default: () => {}
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    spanMethod: {
      type: Function,
      default: null
    },
    stripe: {
      type: Boolean,
      default: true
    },
    operationFixed: {
      type: String,
      default: null
    },
    operationDropdown: {
      type: Boolean,
      default: false
    },
    sortChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      tableHeight: 0
    };
  },
  mounted() {
    setTimeout(() => {
      let paginationBottom = 80;
      if (document.querySelector('.pagination-block')) {
        paginationBottom = document.querySelector('.pagination-block').getBoundingClientRect().bottom;
      }
      const pageHeight = document.documentElement.clientHeight;
      const containerPadding = 30;
      const paginationPadding = 0;
      this.tableHeight = pageHeight - paginationBottom - containerPadding - paginationPadding;
    }, 200);
  },
  methods: {
    operationsToDropdown(row) {
      if (this.operations.length > 1) {
        return this.operations.slice(1).filter(o => o.show == null || o.show(row));
      }
      return [];
    }
  }
};