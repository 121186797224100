"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = require("@/api/common");
var _index = _interopRequireDefault(require("../basic/index.vue"));
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
var _default = exports.default = {
  components: {
    Basic: _index.default,
    EditDialog: _EditDialog.default
  },
  data() {
    return {
      endpoint: '/role/',
      requestParams: [{
        key: 'name'
      }],
      allChannels: [],
      allProducts: [],
      adjustDialogVisible: 0,
      adjustValue: [],
      adjustingRow: null
    };
  },
  async created() {
    let res = await (0, _common.getListAll)('/channel/');
    if (res.code === 0) {
      this.allChannels = res.data.map(e => ({
        key: e.id,
        label: e.name
      }));
    }
    res = await (0, _common.getListAll)('/product/');
    if (res.code === 0) {
      this.allProducts = res.data.map(e => ({
        key: e.id,
        label: e.name
      }));
    }
  },
  methods: {
    async dataListFunction() {
      const res = await (0, _common.get)('/role/');
      return {
        data: {
          items: res.data
        }
      };
    },
    async adjustChannel(row) {
      const res = await (0, _common.get)(`/role/${row.id}/setup/channel/`);
      if (res.code === 0) {
        this.adjustValue = res.data.map(e => e.id);
      }
      this.adjustingRow = row;
      this.adjustDialogVisible = 1;
    },
    async adjustProduct(row) {
      const res = await (0, _common.get)(`/role/${row.id}/setup/product/`);
      if (res.code === 0) {
        this.adjustValue = res.data.map(e => e.id);
      }
      this.adjustingRow = row;
      this.adjustDialogVisible = 2;
    },
    async adjusted() {
      if (this.adjustDialogVisible === 0) {
        return;
      }
      if (this.adjustDialogVisible === 1) {
        const res = await (0, _common.update)(`/role/${this.adjustingRow.id}/setup/channel/`, {
          channel_id_list: this.adjustValue
        });
        if (res.code === 0) {
          this.$message({
            message: this.$t('common.operation_success'),
            type: 'success'
          });
        }
      } else if (this.adjustDialogVisible === 2) {
        const res = await (0, _common.update)(`/role/${this.adjustingRow.id}/setup/product/`, {
          product_id_list: this.adjustValue
        });
        if (res.code === 0) {
          this.$message({
            message: this.$t('common.operation_success'),
            type: 'success'
          });
        }
      }
    }
  }
};