"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/index.scss");
require("element-ui/lib/theme-chalk/index.css");
var _index3 = _interopRequireDefault(require("@/i18n/index"));
var _echarts = _interopRequireDefault(require("echarts"));
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
// import 'normalize.css/normalize.css'

// global css

// icon
// permission control
// Vue.use(ElementUI, { locale });
_vue.default.use(_elementUi.default);
_vue.default.use(_vueLazyload.default);
_vue.default.config.productionTip = false;
_vue.default.prototype.$echarts = _echarts.default;
const vm = new _vue.default({
  i18n: _index3.default,
  store: _store.default,
  router: _router.default,
  render: h => h(_App.default)
}).$mount('#app');
window.vue = vm;