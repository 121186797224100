"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
var _common = require("@/api/common");
var _index2 = _interopRequireDefault(require("../basic/index.vue"));
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
var _default = exports.default = {
  components: {
    Basic: _index2.default,
    EditDialog: _EditDialog.default
  },
  data() {
    return {
      endpoint: '/user/',
      requestParams: [{
        key: 'username',
        canEdit: false
      }, {
        key: 'name',
        canEdit: false
      }, {
        key: 'role.name',
        label: 'user.role',
        canEdit: false
      }, {
        key: 'status',
        type: 'switch',
        canEdit: false,
        onChange: async row => {
          const res = await (0, _common.update)(`/user/${row.id}`, {
            status: !row.status,
            role_id: row.role.id
          });
          if (res.code === 0) {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success'
            });
            this.$refs.table.loadList();
          }
        }
      }],
      form: {},
      roles: [],
      addUser: false,
      allChannels: [],
      allProducts: [],
      adjustDialogVisible: 0,
      adjustValue: [],
      adjustingRow: null
    };
  },
  async created() {
    let res = await (0, _common.getList)('/role/');
    if (res.code === 0) {
      this.roles = res.data;
    }
    res = await (0, _common.getListAll)('/channel/');
    if (res.code === 0) {
      this.allChannels = res.data.map(e => ({
        key: e.id,
        label: e.name
      }));
    }
    res = await (0, _common.getListAll)('/product/');
    if (res.code === 0) {
      this.allProducts = res.data.map(e => ({
        key: e.id,
        label: e.name
      }));
    }
  },
  methods: {
    onEditRow(row) {
      if (row != null) {
        this.form = (0, _index.deepClone)(row);
        this.form.role_id = this.form.role.id;
        this.addUser = false;
      } else {
        this.form = {
          status: true
        };
        this.addUser = true;
      }
      return row;
    },
    formatRequestData() {
      if (this.form.status == null) {
        this.form.status = false;
      }
      return this.form;
    },
    canConfirm() {
      return this.form != null && this.form.role_id != null && (!this.addUser || this.form.username != null && this.form.username.length >= 5) && (!this.addUser || this.form.password != null && this.form.password.length >= 5);
    },
    async adjustChannel(row) {
      const res = await (0, _common.get)(`/user/${row.id}/setup/channel/`);
      if (res.code === 0) {
        this.adjustValue = res.data.map(e => e.id);
      }
      this.adjustingRow = row;
      this.adjustDialogVisible = 1;
    },
    async adjustProduct(row) {
      const res = await (0, _common.get)(`/user/${row.id}/setup/product/`);
      if (res.code === 0) {
        this.adjustValue = res.data.map(e => e.id);
      }
      this.adjustingRow = row;
      this.adjustDialogVisible = 2;
    },
    async adjusted() {
      if (this.adjustDialogVisible === 0) {
        return;
      }
      if (this.adjustDialogVisible === 1) {
        const res = await (0, _common.update)(`/user/${this.adjustingRow.id}/setup/channel/`, {
          channel_id_list: this.adjustValue
        });
        if (res.code === 0) {
          this.$message({
            message: this.$t('common.operation_success'),
            type: 'success'
          });
        }
      } else if (this.adjustDialogVisible === 2) {
        const res = await (0, _common.update)(`/user/${this.adjustingRow.id}/setup/product/`, {
          product_id_list: this.adjustValue
        });
        if (res.code === 0) {
          this.$message({
            message: this.$t('common.operation_success'),
            type: 'success'
          });
        }
      }
    }
  }
};