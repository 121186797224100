"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setup = exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
/* eslint-disable global-require */

_vue.default.use(_vueI18n.default);
const DEFAULT_LANG = 'zh';
const LOCALE_KEY = 'DemoLanguage';
const locales = {
  zh: require('./zh.json'),
  en: require('./en.json')
};
const i18n = new _vueI18n.default({
  locale: DEFAULT_LANG,
  messages: locales,
  silentTranslationWarn: true
});
const UIlocales = {
  zh: _zhCN.default,
  en: _en.default
};
const setUIlocales = lang => {
  switch (lang) {
    case 'zh':
      return UIlocales.zh;
    default:
      return UIlocales.en;
  }
};
const setup = lang => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang);
  Object.keys(locales).forEach(item => {
    document.body.classList.remove(`lang-${item}`);
  });
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute('lang', lang);
  _vue.default.config.lang = lang;
  i18n.locale = lang;
  _locale.default.use(setUIlocales(lang));
};
exports.setup = setup;
setup();
var _default = exports.default = i18n;