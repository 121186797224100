"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.unshift.js");
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = _interopRequireDefault(require("@/components/Upload/index.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _index2 = require("@/utils/index");
var _elementChinaAreaData = require("element-china-area-data");
var _index3 = _interopRequireDefault(require("../basic/index.vue"));
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
const xlsxCols = ['push_status_name', 'erp_trade_created', 'reason', 'erp_trade_code', 'platform_trade_code', 'receiver_name', 'receiver_mobile', 'receiver_province', 'receiver_city', 'receiver_district', 'receiver_address', 'warehouse_code', 'owner', 'seller_memo', 'express_name', 'express_code', 'delivery_at'];
const tableColsKey = 'bdoms-tableCols';
var _default = exports.default = {
  components: {
    Basic: _index3.default,
    Upload: _index.default,
    EditDialog: _EditDialog.default
  },
  mixins: [_mixins.default.exportCsv],
  data() {
    const isAdmin = _store.default.getters.permissions.filter(e => e.code === 'admin').length > 0;
    const isBusiness = _store.default.getters.permissions.filter(e => e.code === 'business').length > 0;
    let tableCols = window.localStorage.getItem(tableColsKey);
    if (tableCols) {
      tableCols = JSON.parse(tableCols);
    } else {
      tableCols = null;
    }
    let requestParams = [{
      key: 'note',
      inputType: 'textarea',
      showInTable: false,
      formItemStyleClass: 'new-line',
      hide: () => !this.editNote
    }, {
      key: 'vip_code',
      remoteSearch: '/channel/',
      searchKey: row => row.channel.name || '',
      initValue: row => row.channel.name || '',
      optionKey: 'erp_vip_code',
      showInTable: false,
      label: this.$t('trade.select_channel'),
      disabled: () => isBusiness || this.editNote
    }, {
      key: 'push_status_name',
      canEdit: false,
      style: this.pushStyle,
      tooltip: row => {
        if (!isBusiness && row.reason != null && row.reason.length > 0) {
          return `${this.$t('trade.reason')}:${row.reason}`;
        }
        return null;
      },
      showColumn: this.initShowColumnValue(tableCols, 'push_status_name', true),
      width: 80
    }];
    if (!isBusiness) {
      requestParams = requestParams.concat([{
        key: 'erp_trade_created',
        width: 150,
        canEdit: false,
        showColumn: this.initShowColumnValue(tableCols, 'erp_trade_created', true)
      }, {
        key: 'channel.name',
        width: 100,
        label: 'trade.channel',
        canEdit: false,
        value: row => {
          if (row.channel.name) {
            return row.channel.name;
          }
          return row.vip_code;
        },
        style: row => {
          if (row.channel.name == null) {
            return 'color: #F56C6C';
          }
          return '';
        },
        showColumn: this.initShowColumnValue(tableCols, 'channel.name', true)
      }]);
    }
    requestParams = requestParams.concat([{
      key: 'warehouse_code',
      disabled: () => isBusiness || this.editNote,
      showInTable: !isBusiness,
      showColumn: this.initShowColumnValue(tableCols, 'warehouse_code', true)
    }, {
      key: 'addr_parser',
      notRequired: true,
      showInTable: false,
      formItemStyleClass: 'addr-detail',
      slot: 'addr_parser'
    }, {
      key: 'region',
      showInTable: false,
      inputType: 'region',
      label: this.$t('trade.area'),
      handleChange: (e, form) => {
        if (e && e.length === 3) {
          form.region = e;
          form.receiver_province = _elementChinaAreaData.CodeToText[e[0]];
          form.receiver_city = _elementChinaAreaData.CodeToText[e[1]];
          form.receiver_district = _elementChinaAreaData.CodeToText[e[2]];
        } else {
          form.receiver_province = null;
          form.receiver_city = null;
          form.receiver_district = null;
        }
      },
      notRequired: true,
      disabled: () => this.editNote
    }, {
      key: 'receiver_address',
      showInTable: false,
      disabled: () => this.editNote
    }, {
      key: 'receiver_name',
      width: 100,
      disabled: () => this.editNote,
      showColumn: this.initShowColumnValue(tableCols, 'receiver_name', true)
    }, {
      key: 'receiver_mobile',
      width: 130,
      disabled: () => this.editNote,
      showColumn: this.initShowColumnValue(tableCols, 'receiver_mobile', true)
    }, {
      key: 'display_addr',
      width: 180,
      canEdit: false,
      showColumn: this.initShowColumnValue(tableCols, 'display_addr', false)
    }, {
      key: 'platform_trade_code',
      notRequired: true,
      width: 150,
      formItemStyleClass: 'new-line',
      canEdit: !isBusiness,
      disabled: () => this.editNote,
      // eslint-disable-next-line no-nested-ternary
      tooltip: row => row.note == null ? null : row.note.length === 0 ? null : row.note,
      style: row => {
        if (row.note != null && row.note.length > 0) {
          return 'color: #409EFF;font-weight: bold;';
        }
        return '';
      },
      showColumn: this.initShowColumnValue(tableCols, 'platform_trade_code', true)
    }]);
    if (!isBusiness) {
      requestParams = requestParams.concat([{
        key: 'seller_memo',
        inputType: 'textarea',
        notRequired: true,
        formItemStyleClass: 'new-line',
        disabled: () => this.editNote,
        showColumn: this.initShowColumnValue(tableCols, 'seller_memo', false)
      }, {
        key: 'owner',
        remoteAutoComplete: this.userRemoteAutoComplete,
        notRequired: true,
        formItemStyleClass: 'new-line',
        label: this.$t('trade.owner'),
        disabled: () => this.editNote,
        showColumn: this.initShowColumnValue(tableCols, 'owner', true)
      }]);
    } else {
      requestParams.push({
        key: 'seller_memo',
        inputType: 'textarea',
        notRequired: true,
        formItemStyleClass: 'new-line',
        disabled: () => this.editNote,
        showColumn: this.initShowColumnValue(tableCols, 'seller_memo', false)
      });
    }
    requestParams = requestParams.concat([{
      key: 'express_name',
      width: 100,
      canEdit: false,
      showColumn: this.initShowColumnValue(tableCols, 'express_name', false)
    }, {
      key: 'express_code',
      canEdit: false,
      width: 130,
      showColumn: this.initShowColumnValue(tableCols, 'express_code', false)
    }, {
      key: 'delivery_at',
      canEdit: false,
      width: 150,
      showColumn: tableCols ? tableCols.delivery_at : true,
      sortable: 'custom'
    }, {
      key: 'item.is_matched',
      canEdit: false,
      value: row => row.item.is_matched ? '●' : '○',
      style: row => row.item.is_matched ? 'color: #34C759; font-size: 30px;' : 'color: red; font-size: 16px;',
      showColumn: this.initShowColumnValue(tableCols, 'item.product.name', false),
      canEditColumn: false
    }, {
      key: 'item.product.name',
      label: 'trade.product',
      canEdit: false,
      width: 150,
      value: row => {
        if (row.item.product.name != null) {
          return row.item.product.name;
        }
        return row.item.item_code;
      },
      style: row => {
        if (row.item.product.name == null) {
          return 'color: #F56C6C';
        }
        return '';
      },
      showColumn: this.initShowColumnValue(tableCols, 'item.product.name', false)
    }, {
      key: 'item.quantity',
      canEdit: false,
      width: 50,
      showColumn: this.initShowColumnValue(tableCols, 'item.product.name', false),
      canEditColumn: false
    }]);
    if (isAdmin) {
      requestParams.push({
        key: 'item.price',
        canEdit: false,
        width: 80,
        showColumn: this.initShowColumnValue(tableCols, 'item.product.name', false),
        canEditColumn: false
      });
    }
    requestParams.push({
      key: 'create_at',
      label: 'common.create_at',
      canEdit: false,
      showColumn: this.initShowColumnValue(tableCols, 'create_at', false)
    });
    let searchBarRightButtons = [];
    if (!isBusiness) {
      searchBarRightButtons = [{
        title: 'common.export',
        onClick: this.handleExport
      }, {
        title: 'common.import',
        onClick: () => {
          this.uploadVisible = true;
        }
      }, {
        title: 'trade.push',
        onClick: this.handlePush
      }, {
        title: 'trade.ship.name',
        onClick: () => {
          this.shipVisible = true;
        }
      }, {
        title: 'trade.completedOrderUpload',
        onClick: () => {
          this.completedOrderUploadVisible = true;
        }
      },
      // , {
      //   title: 'trade.shippable.name',
      //   onClick: () => {
      //     this.shippableVisible = true;
      //   },
      // }
      {
        title: 'trade.batch_delete',
        type: 'danger',
        onClick: this.batchDelete,
        disabled: () => this.removeSelection.length === 0
      }];
    }
    searchBarRightButtons.push({
      icon: 'el-icon-s-tools',
      // eslint-disable-next-line no-return-assign
      onClick: () => this.tableColDialogVisible = true,
      style: 'margin-left: auto;'
    });
    return {
      endpoint: '/trade/',
      requestParams,
      uploadVisible: false,
      uploadUrl: `${process.env.VUE_APP_BASE_API}/trade/upload`,
      uploadTemplateUrl: `${process.env.VUE_APP_STATIC}/page/static/template/import_trade_tempalte_20220308.xlsx`,
      selectedRegions: [],
      productItems: [],
      searchOptions: [],
      shipVisible: false,
      shipUrl: `${process.env.VUE_APP_BASE_API}/trade/multiple_express/upload`,
      shipTemplateUrl: `${process.env.VUE_APP_STATIC}/page/static/template/import_trade_multiple_express_tempalte_20220513.xlsx`,
      removeSelection: [],
      shipDialogVisible: false,
      shipForm: {
        expresses: {}
      },
      shipRow: null,
      searchBarRightButtons,
      isBusiness,
      dialogVisible: false,
      imported: null,
      shippableVisible: false,
      shippableUrl: `${process.env.VUE_APP_BASE_API}/trade/deliverable/upload`,
      shippableTemplateUrl: `${process.env.VUE_APP_STATIC}/page/static/template/import_trade_deliverable_tempalte_20220421.xlsx`,
      deliverable: 0,
      deliverableOptions: [{
        value: 0,
        label: this.$t('trade.shippable.status.0')
      }, {
        value: 1,
        label: this.$t('trade.shippable.status.1')
      }],
      expressNo: [1, 2, 3],
      completedOrderUploadVisible: false,
      completedOrderUploadUrl: `${process.env.VUE_APP_BASE_API}/trade/completed/upload`,
      completedOrderUploadTemplateUrl: `${process.env.VUE_APP_STATIC}/page/static/template/import_trade_completed_tempalte_20220617.xlsx`,
      editNote: false,
      updateEndpoint: null,
      detailDialogVisible: false,
      rowDetail: null,
      tableColDialogVisible: false,
      device: null,
      addParserText: null,
      editDialogWidth: 1000,
      latestSamples: [],
      latestSamplesKey: ''
    };
  },
  async created() {
    this.latestSamplesKey = `${_store.default.getters.name}_bdoms_latest_samples_mul`;
    const {
      code,
      data
    } = await (0, _common.get)('/brand/detail_all');
    if (code === 0) {
      this.searchOptions = data.map(e => {
        const children = e.products.map(p => ({
          value: p.erp_code,
          label: p.name,
          idx: p.idx
        }));
        children.sort((a, b) => b.idx - a.idx);
        return {
          value: e.name,
          label: e.name,
          idx: e.idx,
          children
        };
      });
      this.searchOptions.sort((a, b) => b.idx - a.idx);
      this.loadLatestSamples();
    }
    this.device = _store.default.getters.device;
    if (window.innerWidth < 800) {
      this.editDialogWidth = 380;
    }
  },
  methods: {
    exportRequest() {
      const requestData = {
        ...this.$refs.table.query
      };
      requestData.page = this.page_exporting;
      requestData.per_page = this.per_page_exporting;
      return (0, _common.getList)('/trade/', requestData);
    },
    exportMap(item) {
      const map = {};
      Object.keys(item).forEach(k => {
        if (xlsxCols.indexOf(k) >= 0) {
          if (k === 'is_matched') {
            map[this.$t(`trade.${k}`)] = item[k] ? '匹配' : '未匹配';
          } else {
            map[this.$t(`trade.${k}`)] = item[k];
          }
        }
        if (k === 'item') {
          map[this.$t('trade.is_matched')] = item[k].is_matched;
          map[this.$t('product.name')] = item[k].product.name;
          map[this.$t('product.erp_code')] = item[k].product.erp_code;
          map['管家婆产品编码'] = item[k].product.gjp_code;
          map[this.$t('product.gjp_name')] = item[k].product.gjp_name;
          map[this.$t('trade.quantity')] = item[k].quantity;
          map[this.$t('trade.price')] = item[k].price;
        }
        if (k === 'channel') {
          map[this.$t('channel.erp_shop_code')] = item[k].erp_shop_code;
          map[this.$t('channel.erp_vip_code')] = item[k].erp_vip_code;
          map[this.$t('channel.gjp_code')] = item[k].gjp_code;
        }
      });
      return map;
    },
    outputName() {
      return `${(0, _index2.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导出订单.xlsx`;
    },
    outputHeader() {
      return xlsxCols.map(k => this.$t(`trade.${k}`));
    },
    async handleUploaded() {
      // this.$message({
      //   message: this.$t('common.operation_success'),
      //   type: 'success',
      // });
      this.$refs.table.loadList();
    },
    async handlePush() {
      const loading = this.$loading({
        lock: true,
        text: '正在推送',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const res = await (0, _common.update)('/trade/push');
      if (res.code === 0) {
        this.$alert(`推送成功 ${res.data.success}, 失败 ${res.data.failed}`, this.$t('common.alert'), {
          confirmButtonText: this.$t('common.confirm'),
          callback: () => {
            this.$refs.table.loadList();
          }
        });
      }
      loading.close();
    },
    pushStyle(row) {
      const style = color => `color: ${color}; border: ${color} 1px solid;padding: 0px 5px; border-radius: 8px;`;
      switch (row.push_status) {
        case 0:
          return style('gray');
        case 1:
          return style('#34C759');
        case 2:
          return style('red');
        default:
          return '';
      }
    },
    uploadResponse(res) {
      const {
        code,
        data
      } = res;
      if (code === 0) {
        if (this.completedOrderUploadVisible || this.uploadVisible || this.shipVisible) {
          this.imported = data;
          this.completedOrderUploadVisible = false;
          this.uploadVisible = false;
          this.shipVisible = false;
          if (this.imported != null) {
            this.dialogVisible = true;
          }
        }
        if (data.include_erp_trade_code_count && data.updated_trade_deliverable_count) {
          this.shippableVisible = false;
          this.$alert(`${this.$t('trade.shippable.include_erp_trade_code_count')}: ${data.include_erp_trade_code_count}, ${this.$t('trade.shippable.updated_trade_deliverable_count')}: ${data.updated_trade_deliverable_count}.`, this.$t('common.upload_success'), {
            confirmButtonText: this.$t('common.confirm')
          });
        } else {
          this.$message({
            message: this.$t('common.upload_success'),
            type: 'success'
          });
        }
        this.handleUploaded();
      } else {
        this.$message.error(res.error_message);
        try {
          this.$refs.upload.$refs.upload.clearFiles();
          this.$refs.ship.$refs.upload.clearFiles();
          this.$refs.shippable.$refs.upload.clearFiles();
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    },
    async userRemoteAutoComplete(query, cb) {
      const res = await (0, _common.getListAll)('/user/', {
        keyword: query,
        role_id: 3
      });
      if (res != null && res.code === 0) {
        cb(res.data.map(e => ({
          ...e,
          value: e.username
        })));
      }
    },
    onEditRow(row) {
      if (row == null) {
        this.productItems = [];
        this.addItem();
      } else {
        this.productItems = row.items.map(e => ({
          id: e.product.id,
          quantity: e.quantity,
          name: e.product.name,
          erp_code: e.product.erp_code,
          item_code: e.product.erp_code
        }));
      }
      if (row != null) {
        if (row.receiver_province && _elementChinaAreaData.TextToCode[row.receiver_province]) {
          row.region = [_elementChinaAreaData.TextToCode[row.receiver_province].code];
          if (row.receiver_city && _elementChinaAreaData.TextToCode[row.receiver_province][row.receiver_city]) {
            row.region.push(_elementChinaAreaData.TextToCode[row.receiver_province][row.receiver_city].code);
            if (row.receiver_district && _elementChinaAreaData.TextToCode[row.receiver_province][row.receiver_city][row.receiver_district]) {
              row.region.push(_elementChinaAreaData.TextToCode[row.receiver_province][row.receiver_city][row.receiver_district].code);
            }
          }
        }
      }
      return row;
    },
    addItem() {
      this.productItems.push({
        id: new Date().getTime(),
        quantity: 1
      });
    },
    deleteItem(idx) {
      this.productItems.splice(idx, 1);
    },
    handleCascaderChange() {
      this.$forceUpdate();
    },
    canConfirm(form) {
      if (this.editNote) {
        return form.note != null;
      }
      if (form.region != null && form.region.length === 3 && form.receiver_province == null && form.receiver_city == null && form.receiver_district == null) {
        form.receiver_province = _elementChinaAreaData.CodeToText[form.region[0]];
        form.receiver_city = _elementChinaAreaData.CodeToText[form.region[1]];
        form.receiver_district = _elementChinaAreaData.CodeToText[form.region[2]];
      }
      return this.productItems.length > 0 && this.productItems.filter(e => e.item_code == null || e.item_code.length === 0).length === 0 && form.vip_code != null && form.vip_code.length > 0 && form.receiver_address != null && form.receiver_address.length > 0 && form.receiver_mobile != null && form.receiver_mobile.length > 0 && form.receiver_name != null && form.receiver_name.length > 0 && form.warehouse_code != null && form.warehouse_code.length > 0;
    },
    formatRequestData(requestData) {
      if (this.editNote) {
        return {
          note: requestData.note
        };
      }
      return {
        ...requestData,
        items: this.productItems.map(e => ({
          ...e,
          item_code: typeof e.item_code === 'string' ? e.item_code : e.item_code[1]
        }))
      };
    },
    formatDataList(dataList) {
      const product = this.requestParams.filter(e => e.key === 'item.product.name')[0];
      if (!product.showColumn) {
        return dataList;
      }
      const list = [];
      dataList.forEach(e => {
        let isFirst = true;
        e.items.forEach(i => {
          list.push({
            ...e,
            item: i,
            isFirst
          });
          isFirst = false;
        });
      });
      return list;
    },
    spanMethod({
      row,
      columnIndex
    }) {
      const columns = this.requestParams.filter(e => e.showColumn === true);
      const productIndex = columns.findIndex(e => e.key === 'item.is_matched');
      if (productIndex < 0) {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
      const spanCols = this.isBusiness ? [productIndex - 1, productIndex, productIndex + 1] : [productIndex + 1, productIndex + 2, productIndex + 3, productIndex + 4];
      if (row.items.length > 1) {
        if (spanCols.indexOf(columnIndex) < 0 && row.isFirst) {
          return {
            rowspan: row.items.length,
            colspan: 1
          };
        }
        if (spanCols.indexOf(columnIndex) >= 0) {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
        return {
          rowspan: 0,
          colspan: 1
        };
      }
      return {
        rowspan: 1,
        colspan: 1
      };
    },
    handleSelectionChange(rows) {
      if (rows) {
        this.removeSelection = rows;
      }
    },
    async batchDelete() {
      this.$confirm(this.$t('trade.batch_delete'), this.$t('trade.batch_delete_msg'), {
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await (0, _common.update)('/trade/batch/delete', {
          trade_id_list: this.removeSelection.map(e => e.id)
        });
        if (res.code === 0) {
          this.$message({
            message: this.$t('common.operation_success'),
            type: 'success'
          });
          this.$refs.table.loadList();
        }
      }).catch(() => {
        this.$message({
          message: this.$t('common.operation_cancelled'),
          type: 'warning'
        });
      });
    },
    onOpenShipDialog(row) {
      this.shipForm = {
        express_name_1: null,
        express_code_1: null,
        express_name_2: null,
        express_code_2: null,
        express_name_3: null,
        express_code_3: null
      };
      this.expressNo.forEach(i => {
        const ex = row.expresses.length >= i ? row.expresses[i - 1] : {};
        const name = ex.express_name;
        const code = ex.express_code;
        this.shipForm[`express_name_${i}`] = name;
        this.shipForm[`express_code_${i}`] = code;
      });
      this.shipRow = row;
      this.shipDialogVisible = true;
    },
    shipDialogCanConfirm() {
      return this.shipForm.express_name_1 != null && this.shipForm.express_name_1.length > 0 && this.shipForm.express_code_1 != null && this.shipForm.express_code_1.length > 0;
    },
    async shipDialogConfirm() {
      const expresses = [];
      this.expressNo.forEach(i => {
        const name = this.shipForm[`express_name_${i}`];
        const code = this.shipForm[`express_code_${i}`];
        if (name != null && name.length > 0 && code != null && code.length > 0) {
          expresses.push({
            express_name: name,
            express_code: code
          });
        }
      });
      const res = await (0, _common.update)(`/trade/${this.shipRow.id}/multiple_express`, {
        expresses
      });
      if (res.code === 0) {
        this.$message({
          message: this.$t('common.operation_success'),
          type: 'success'
        });
        this.$refs.table.loadList();
      }
    },
    afterHandleOpen(form) {
      if (this.isBusiness && form.vip_code.length === 0) {
        return {
          ...form,
          vip_code: '商务寄样',
          warehouse_code: 'CZQP'
        };
      }
      return form;
    },
    // reorderTableCols(cols) {
    //   if (this.isBusiness) {
    //     [cols[4], cols[0]] = [cols[0], cols[4]];
    //   } else {
    //     [cols[3], cols[7]] = [cols[7], cols[3]];
    //   }
    //   return cols;
    // },
    downloadFailedImport() {
      if (this.imported != null && this.imported.failed_platform_trade_code_list != null) {
        this.exportList(this.imported.failed_platform_trade_code_list.map(e => ({
          [this.$t('trade.import.failed_list')]: e
        })), null, `${(0, _index2.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导入失败订单号.xlsx`);
      }
    },
    onCopy(row) {
      const editRow = (0, _index2.deepClone)(row);
      editRow.id = null;
      editRow.platform_trade_code = null;
      this.$refs.table.onEdit(editRow);
    },
    onNote(row) {
      this.updateEndpoint = `/trade/${row.id}/note`;
      this.editNote = true;
      this.$refs.table.onEdit(row);
    },
    handleDialogClose() {
      this.updateEndpoint = null;
      this.editNote = false;
      this.addParserText = null;
    },
    operationStyle(o) {
      if (o === 'edit') {
        return 'color:#67C23A;';
      }
      if (o === 'delete') {
        return 'color:#F56C6C;';
      }
      return '';
    },
    flattenItems(items) {
      const output = [];
      items.forEach(i => {
        output.push({
          label: 'trade.item.is_matched',
          value: i.is_matched ? '●' : '○',
          style: i.is_matched ? 'color: #34C759; font-size: 30px; line-height: 0.68;' : 'color: red; font-size: 16px; line-height: 1.3;'
        });
        output.push({
          label: 'trade.product',
          value: i.product.name,
          span: 2
        });
        output.push({
          label: 'trade.item.quantity',
          value: i.quantity
        });
        output.push({
          label: 'trade.item.price',
          value: i.price
        });
      });
      return output;
    },
    updateTableCols() {
      const product = this.requestParams.filter(e => e.key === 'item.product.name')[0];
      this.requestParams.forEach(e => {
        if (e.key === 'item.is_matched' || e.key === 'item.quantity' || e.key === 'item.price') {
          e.showColumn = product.showColumn;
        }
      });
      this.$refs.table.loadList();
      window.localStorage.setItem(tableColsKey, JSON.stringify(this.requestParams.reduce((a, c) => {
        a[c.key] = c.showColumn;
        return a;
      }, {})));
    },
    initShowColumnValue(tableCols, key, defaultValue) {
      if (tableCols == null || tableCols[key] == null) {
        return defaultValue;
      }
      return tableCols[key];
    },
    sortChange({
      order
    }) {
      if (order == null) {
        this.$refs.table.query.sort_method = null;
        this.$refs.table.query.sort_direction = null;
      } else {
        this.$refs.table.query.sort_method = 'delivery_at';
        this.$refs.table.query.sort_direction = order === 'descending' ? 'desc' : 'asc';
      }
      this.$refs.table.loadList();
    },
    async handleParseAddr() {
      const {
        code,
        data
      } = await (0, _common.get)('/baidu/parse_addr', {
        text: this.addParserText
      });
      if (code === 0) {
        const {
          form
        } = this.$refs.table.$refs.dialog;
        form.receiver_name = data.name;
        form.receiver_mobile = data.mobile;
        form.receiver_address = data.detail;
        form.receiver_province = _elementChinaAreaData.CodeToText[data.province_code];
        form.receiver_city = _elementChinaAreaData.CodeToText[data.city_code];
        form.receiver_district = _elementChinaAreaData.CodeToText[data.district_code];
        form.region = [data.province_code, data.city_code, data.district_code];
      }
    },
    loadLatestSamples() {
      const storeLatest = localStorage.getItem(this.latestSamplesKey);
      if (storeLatest && this.searchOptions.length > 0) {
        this.latestSamples = JSON.parse(storeLatest);
      }
    },
    handleLatestSampleSelect(val) {
      const selected = (0, _index2.deepClone)(val.value);
      if (selected.length > 0) {
        if (this.productItems.length === 1 && this.productItems[0].item_code == null) {
          // eslint-disable-next-line prefer-destructuring
          this.productItems[0] = selected[0];
          this.productItems.push(...selected.splice(1));
          this.$forceUpdate();
        } else {
          this.productItems.push(...selected);
        }
      }
    },
    latestItemLabel(item) {
      return item.value.map(e => `[${e.name}] x ${e.quantity}`).join(', ');
    },
    handleConfirm() {
      const clone = (0, _index2.deepClone)(this.productItems);
      const key = clone.reduce((a, c) => `${a}_${c.item_code[1]}`, '');
      const allProducts = this.searchOptions.map(o => o.children).flat();
      const value = clone.map(e => {
        let code;
        if (typeof e.item_code === 'string') {
          code = e.item_code;
        } else {
          // eslint-disable-next-line prefer-destructuring
          code = e.item_code[1];
        }
        return {
          item_code: e.item_code,
          quantity: e.quantity,
          name: allProducts.filter(o => o.value === code)[0].label
        };
      });
      const idx = this.latestSamples.findIndex(e => e.key === key);
      let latestSamplesClone = this.latestSamples;
      if (idx >= 0) {
        latestSamplesClone[idx] = {
          key,
          value
        };
        [latestSamplesClone[0], latestSamplesClone[idx]] = [latestSamplesClone[idx], latestSamplesClone[0]];
      } else {
        latestSamplesClone.unshift({
          key,
          value
        });
      }
      if (latestSamplesClone.length > 5) {
        latestSamplesClone = latestSamplesClone.slice(0, 5);
      }
      localStorage.setItem(this.latestSamplesKey, JSON.stringify(latestSamplesClone));
      this.loadLatestSamples();
    }
  }
};