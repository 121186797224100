"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = require("@/utils/index");
var _index2 = _interopRequireDefault(require("../basic/index.vue"));
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
var _default = exports.default = {
  components: {
    Basic: _index2.default,
    EditDialog: _EditDialog.default
  },
  mixins: [_mixins.default.list, _mixins.default.exportCsv, _mixins.default.clicks],
  data() {
    return {
      endpoint: '/product/',
      brandEndpoint: '/brand/',
      requestParams: [{
        key: 'name'
      }, {
        key: 'gjp_code'
      }, {
        key: 'gjp_name'
      }, {
        key: 'erp_code'
      }, {
        key: 'related_channel_count',
        canEdit: false
      }, {
        key: 'brand_id',
        showInTable: false,
        label: 'product.brand.name',
        options: () => this.dataList.filter(e => e.id != null)
      }, {
        key: 'idx',
        inputType: 'integer',
        notRequired: true
      }],
      activeName: null
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
      const {
        data,
        code
      } = await (0, _common.getListAll)(this.brandEndpoint);
      if (code === 0) {
        this.dataList = [{
          name: this.$t('product.brand.all'),
          id: null
        }];
        this.dataList = this.dataList.concat(data);
        const selectedGroup = this.dataList.filter(e => e.name === this.activeName);
        if (selectedGroup.length === 0) {
          this.activeName = this.dataList[0].name;
        }
        if (this.$refs.basic) {
          this.$refs.basic.loadList();
        }
      }
    },
    exportRequest() {
      const requestData = {
        ...this.$refs.basic.query
      };
      requestData.page = this.page_exporting;
      requestData.per_page = this.per_page_exporting;
      return (0, _common.getList)(this.endpoint, requestData);
    },
    exportMap(item) {
      const map = {};
      const ignored = ['id', 'create_at', 'update_at', 'brand_id', 'brand'];
      Object.keys(item).forEach(k => {
        if (ignored.indexOf(k) < 0) {
          map[this.$t(`product.${k}`)] = item[k];
        }
      });
      return map;
    },
    outputName() {
      return `${(0, _index.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导出产品.xlsx`;
    },
    editGroup(target, action) {
      if (action === 'add') {
        this.editObject = null;
        this.editDialogVisible = true;
      } else if (action === 'remove') {
        const [obj] = this.dataList.filter(e => e.name === target && e.id != null);
        if (obj) {
          this.handleDelete(obj, 'product.brand.delete_msg', 'common.alert', val => (0, _common.deleteItem)(this.brandEndpoint, val.id));
        }
      }
    },
    tabClicked(val) {
      if (val === 1) {
        if (this.$refs.basic) {
          this.$refs.basic.loadList();
        }
      } else if (val === 2) {
        [this.editObject] = this.dataList.filter(e => e.name === this.activeName && e.id != null);
        if (this.editObject) {
          this.editDialogVisible = true;
        }
      }
    },
    async dataListFunction(requestData) {
      requestData.brand_id = this.dataList.filter(e => e.name === this.activeName)[0].id;
      return (0, _common.getList)(this.endpoint, requestData);
    }
  }
};