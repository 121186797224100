"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _default = exports.default = {
  name: 'SidebarLogo',
  mixins: [_mixins.default.file, _mixins.default.shop],
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: null // '/static/images/logo.jpg',
    };
  },

  computed: {
    computedTitle() {
      return this.$t('title');
    }
  },
  created() {}
};